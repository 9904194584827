import React from 'react'

export default function DoorToDoor () {
  return (
    <svg viewBox="0 0 208 108">
      <defs>
        <style
        >{`.dtd-1,.dtd-2,.dtd-5,.dtd-6,.dtd-8{fill:none;}.dtd-1,.dtd-2,.dtd-4,.dtd-8{stroke:#153356;}.dtd-1,.dtd-6,.dtd-7{stroke-linecap:square;}.dtd-1,.dtd-2,.dtd-4,.dtd-5,.dtd-6,.dtd-7,.dtd-8{stroke-linejoin:round;}.dtd-1,.dtd-2,.dtd-8{stroke-width:4px;}.dtd-2,.dtd-4{stroke-linecap:round;}.dtd-3,.dtd-4{fill:#153356;}.dtd-4{stroke-width:5px;}.dtd-5,.dtd-6,.dtd-7{stroke:#97caad;stroke-width:2px;}.dtd-7{fill:#d4ecdd;}`}</style>
      </defs>

      <line className="dtd-1" x1="173.5" y1="102" x2="62.07" y2="102" />
      <line className="dtd-2" x1="165.46" y1="91.31" x2="159.92" y2="91.31" />
      <polyline
        className="dtd-2"
        points="162.61 55.3 182.6 55.3 191.19 76.19 189.93 91.31 183 91.31"
      />
      <path
        className="dtd-2"
        d="M124.09,85.34a8.33,8.33,0,1,1-8.33,8.33,8.59,8.59,0,0,1,.31-2.28A8.34,8.34,0,0,1,124.09,85.34Z"
      />
      <circle className="dtd-2" cx="174.66" cy="93.67" r="8.33" />
      <path
        className="dtd-3"
        d="M160.39,44.55h-50.9l-.68,8.53h34.56v4H98.49l-.31,4h40.19v4H90.86l-.32,4h42.83v4H107.23l-1.45,18.23h9.55s.43-7,8.76-7,8.49,7,8.49,7h27.34l3.46-43.53A3,3,0,0,0,160.39,44.55Z"
      />
      <polygon
        className="dtd-4"
        points="182.36 55.85 187.59 70.3 173.19 70.3 174.34 55.85 182.36 55.85"
      />
      <polyline
        className="dtd-5"
        points="32.52 72.31 32.52 58.56 21.9 58.56 21.9 67.79"
      />
      <rect className="dtd-5" x="25.54" y="52.61" width="2.95" height="5.89" />
      <rect className="dtd-5" x="36.02" y="67.19" width="8.98" height="5.12" />
      <rect className="dtd-5" x="36.02" y="62.07" width="8.98" height="5.12" />
      <rect className="dtd-5" x="45" y="67.19" width="8.98" height="5.12" />
      <rect className="dtd-5" x="45" y="62.07" width="8.98" height="5.12" />
      <polyline
        className="dtd-5"
        points="62.96 67.19 53.98 67.19 53.98 72.31"
      />
      <rect className="dtd-5" x="53.98" y="62.07" width="8.98" height="5.12" />
      <polyline
        className="dtd-5"
        points="66.64 76.83 71.03 66.11 60.34 67.79 58.36 72.31 32.52 72.31 25.36 72.31 24.27 67.79 21.9 67.79 19.36 67.79 21.47 76.99"
      />
      <path
        className="dtd-6"
        d="M72.06,77.86h0a3,3,0,0,1-4.25,0h0a3,3,0,0,0-4.25,0h0a3,3,0,0,1-4.25,0h0a3,3,0,0,0-4.25,0h0a3.06,3.06,0,0,1-4.25,0h0a3,3,0,0,0-4.25,0h0a2.86,2.86,0,0,1-4.25,0h0a3,3,0,0,0-4.25,0h0a3,3,0,0,1-4.25,0h0a3,3,0,0,0-4.25,0h0a3,3,0,0,1-4.25,0h0a3,3,0,0,0-4.25,0h0a3,3,0,0,1-4.25,0"
      />
      <path className="dtd-7" d="M49.73,28.68a3,3,0,0,1,4.25,0h0" />
      <path className="dtd-7" d="M54,28.68a3,3,0,0,1,4.25,0h0" />
      <path className="dtd-7" d="M55.82,42.23a3,3,0,0,1,4.25,0h0" />
      <path className="dtd-7" d="M60.07,42.23a3,3,0,0,1,4.25,0h0" />
      <path className="dtd-7" d="M31.77,39.71a3,3,0,0,1,4.25,0h0" />
      <path className="dtd-7" d="M36,39.71a3,3,0,0,1,4.25,0h0" />
      <path
        className="dtd-8"
        d="M109.23,74.08l-1.45,17.23h7.55s.43-7,8.76-7,8.49,7,8.49,7h27.34l3.46-43.53a3,3,0,0,0-3-3.23h-48.9l-.68,7.53"
      />
    </svg>
  )
}
