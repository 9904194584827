/* eslint-disable complexity */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { graphql } from 'gatsby'
import { format, parse } from 'date-fns'
import ReactGA from 'react-ga'

import Hero, { Overlap } from '../components/Hero'
import HeroVimeoVideo, {
  Overlap as HeroVimeoVideoOverlap,
} from '../components/HeroVimeoVideo'
import Article, { Title, Text } from '../components/Article'
import Button from '../components/Button'
import Entries from '../components/Entries'
import FromTo from '../components/FromTo'
import ScheduleList from '../components/ScheduleList'
import ContactCard from '../components/ContactCard'
import Tags from '../components/Tags'
import Section from '../components/Section'
import Loader from '../components/Loader'
import ServiceCarousel from '../components/ServiceCarousel'

import imageHelper from '../helpers/image-helper'
import getPortsV2 from '../helpers/get-ports-v2'

import uniqueArray from '../helpers/uniqueArray'

import config from '../../config.json'
const API_SOURCE_V2 = `${config.backend_server_url}_api/schedule/v2/`

export default class Index extends Component {
  constructor() {
    super()

    this.state = {
      schedule: [],
      from: null,
      to: null,
      searches: [],
      input: null,
      ports: [],
      loading: false,
      apiResponseStatusCode: null,
    }
  }

  async componentDidMount() {
    const searches = JSON.parse(window.localStorage.getItem('searches'))

    if (searches) {
      this.setState({ searches: uniqueArray(searches) })
    }

    const ports = await getPortsV2()
    this.setState({ ports })
  }

  storeSearch = ({ from, to }) => {
    const searches = JSON.parse(window.localStorage.getItem('searches')) || []
    searches.unshift({ from, to })

    window.localStorage.setItem(
      'searches',
      JSON.stringify(uniqueArray(searches)),
    )
  }

  findPort = name => {
    const { ports } = this.state
    return (
      ports.find(item => item.name.toLowerCase() === name.toLowerCase()) || {}
    )
  }

  makeList = ({ date = new Date(), from: fromSearch, to: toSearch }) => {
    if (fromSearch && toSearch) {
      const from = this.findPort(fromSearch)
      const to = this.findPort(toSearch)
      this.storeSearch({ from: fromSearch, to: toSearch })

      this.setState({
        date,
        from,
        to,
        schedule: [],
        loading: true,
      })

      if (window) {
        window.history.pushState(
          {},
          'Update URL params without refresh',
          `/?from=${from.name}&to=${to.name}`,
        )
      }

      axios
        .all([
          axios.get(
            `${API_SOURCE_V2}port-to-port/?format=json&from_port=${
              from.id
            }&to_port=${to.id}`,
          ),
          axios.get(`${API_SOURCE_V2}vessels/?format=json`),
        ])
        .then(
          axios.spread(({ data }, { data: vessels }) => {
            const { ports } = this.state
            const schedule = data.map(item => {
              const { name = null } =
                vessels.find(({ imo }) => imo === item.imo_number) || {}

              const departureDate = item.origin_departure_date
              const arrivalDate = item.destination_arrival_date

              return {
                id: `${format(departureDate, 'YYYYMMDDHHmm')}-${format(
                  arrivalDate,
                  'YYYYMMDDHHmm',
                )}`,
                name,
                voyage: item.voyage_number,

                departure: parse(departureDate),
                arrival: parse(arrivalDate),

                closing: parse(departureDate),
                pickUpTime: parse(arrivalDate),
              }
            })

            this.setState({ schedule, loading: false })
          }),
        )
        .catch(error => {
          this.setState({
            loading: false,
            schedule: [],
            apiResponseStatusCode: error.response.status,
          })
        })
    }
  }

  selectTag = values => {
    this.setState({ input: values })
  }

  renderNews = () => {
    const { data } = this.props

    if (!data.ievv) {
      return null
    }

    const { items } = data.ievv.content.find(
      ({ type }) => type === 'ExternalArticleList',
    ) || { items: [] }

    const news = items.map(({ link, image, title, content }) => {
      const getImage = imageHelper(data)
      const img = image && image !== 'null' ? getImage(image) : null

      return {
        image: img,
        to: link,
        title,
        description: content,
      }
    })

    return <Entries type="image" items={news} />
  }

  renderContactCards = data => {
    if (data) {
      return data.users.map(user => <ContactCard key={user} id={user} />)
    }

    return null
  }

  requestTranshipmentEmail = (from, to) => {
    // Log use in Google Analytics
    ReactGA.event({
      category: 'Scheduling Search Results',
      action: 'Request transhipment by email clicked',
      value: `${from.name} – ${to.name}`,
    })

    const emailSubject = encodeURI(
      `Request transhipment route from ${from && from.name} to ${to &&
        to.name}`,
    )

    const emailBody = encodeURI(
      `Hi\n\nI would like to request a transhipment route.\n\nFROM PORT\n${from &&
        from.name}\n\nTO PORT\n${to &&
        to.name}\n\n[Please specify cargo and time of sending]`,
    )

    return `shortsea@ncl.no?subject=${emailSubject}&body=${emailBody}`
  }

  renderScheduleList = () => {
    const {
      data,
      data: { schedulePage },
    } = this.props

    const schedulePageData = schedulePage
      ? schedulePage.content.find(({ type }) => type === 'ContactCardSelect')
      : null

    const { schedule, from, to, date, loading } = this.state
    const showScheduleList = schedule && from && to
    const noResults = schedule.length === 0 && !loading

    if (showScheduleList && noResults) {
      // Log use in Google Analytics
      ReactGA.event({
        category: 'Scheduling Search Results',
        action: 'No direct routes between',
        value: `${from.name} – ${to.name}`,
      })
    }

    if (showScheduleList) {
      const apiStatusCode = this.state.apiResponseStatusCode
      const { heroVimeoVideo } = data.ievv || {}
      let OverlapComponent = Overlap
      if (heroVimeoVideo) {
        OverlapComponent = HeroVimeoVideoOverlap
      }

      let result = null
      if (noResults) {
        if (apiStatusCode === null || apiStatusCode === 404) {
          result = (
            <Article small>
              <Title>
                No direct routes between {from.name} and {to.name}, but we can
                still make it happen
              </Title>
              <Text>
                Contact us and we'll find a transhipment route that works for
                you.
              </Text>
              <Button primary mailto={this.requestTranshipmentEmail(from, to)}>
                Request transhipment by email
              </Button>
              <br />
              <br />
              <br />
              <br />
              {this.renderContactCards(schedulePageData)}
            </Article>
          )
        } else {
          result = (
            <Article small>
              <Title>An error occurred</Title>
              <Text>
                We have been notified and are working to resolve it. Please try
                again later. <strong>({apiStatusCode})</strong>
              </Text>
              <br />
              <br />
              {this.renderContactCards(schedulePageData)}
            </Article>
          )
        }
      }

      return (
        <>
          <OverlapComponent>
            {loading && <Loader />}

            {!noResults && (
              <ScheduleList
                from={from}
                to={to}
                date={date}
                items={schedule}
                contactElementId="contacts"
              />
            )}
            {result}
          </OverlapComponent>

          {schedulePageData && !noResults && (
            <Section id="contacts">
              <Title>{schedulePageData.title}</Title>
              <Text>{schedulePageData.content}</Text>
              {this.renderContactCards(schedulePageData)}
            </Section>
          )}
        </>
      )
    }

    return null
  }

  render() {
    const {
      data,
      data: { about },
    } = this.props

    const { schedule, from, to, searches, input, ports } = this.state
    const showScheduleList = schedule && from && to

    const { heroImage, heroVimeoVideo } = data.ievv || {}
    const getImage = imageHelper(data)
    const hero = heroImage ? getImage(heroImage) : {}

    let banner = (
      <Hero {...hero}>
        <FromTo
          ports={ports}
          onSubmit={this.makeList}
          placeholder="Search ports"
          {...input}
        />
        <Tags items={searches} limit={2} handleClick={this.selectTag} />
      </Hero>
    )
    if (heroVimeoVideo) {
      banner = (
        <HeroVimeoVideo videoId={heroVimeoVideo}>
          <FromTo
            ports={ports}
            onSubmit={this.makeList}
            placeholder="Search ports"
            {...input}
          />
          <Tags items={searches} limit={2} handleClick={this.selectTag} />
        </HeroVimeoVideo>
      )
    }

    return (
      <>
        {banner}

        {this.renderScheduleList()}

        {!showScheduleList && (
          <>
            <ServiceCarousel />
            <Hero
              {...about}
              title="Logistics from the Norwegian Coast to the world"
              left
              cta="This is NCL"
              link="/about"
              preamble="Serving 25+ ports from Europe to Norway, as well as customized visits to industry harbors and ports-on-demand."
            />

            {this.renderNews()}
          </>
        )}
      </>
    )
  }
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

Index.defaultProps = {
  topTask: true,
}

export const query = graphql`
  query {
    ievv(pagetypeid: { eq: "ncl_backend_pageframework.FrontPage" }) {
      heroImage
      heroVimeoVideo
      images {
        id
        alt
        image {
          name
        }
      }
      content {
        type
        items {
          link
          title
          image
          content
        }
      }
    }
    ...GetImages
    schedulePage: ievv(
      pagetypeid: { eq: "ncl_backend_pageframework.Schedule" }
    ) {
      content {
        type
        content
        users
        title
      }
    }

    about: imageSharp(fluid: { originalName: { regex: "/truck_skip/" } }) {
      fluid(maxWidth: 1240) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
