import React from 'react'

export default function Containers () {
  return (
    <svg viewBox="0 0 208 108">
      <defs>
        <style
        >{`.cls-1,.cls-2,.cls-3,.cls-5,.cls-6{fill:none;stroke-linejoin:round;}.cls-1,.cls-2{stroke:#97caad;stroke-width:2px;}.cls-1,.cls-5{stroke-linecap:square;}.cls-2,.cls-3{stroke-linecap:round;}.cls-3,.cls-5,.cls-6{stroke:#153356;stroke-width:4px;}.cls-4{fill:#153356;}`}</style>
      </defs>
      <line className="cls-1" x1="110.14" y1="77.87" x2="26.85" y2="77.87" />
      <rect className="cls-2" x="67.01" y="66.27" width="21.3" height="11.6" />
      <line className="cls-1" x1="79.56" y1="74.77" x2="79.56" y2="69.42" />
      <line className="cls-1" x1="83.41" y1="74.77" x2="83.41" y2="69.42" />
      <line className="cls-1" x1="75.71" y1="74.77" x2="75.71" y2="69.42" />
      <line className="cls-1" x1="71.81" y1="74.77" x2="71.81" y2="69.42" />
      <rect className="cls-2" x="67.01" y="54.67" width="21.3" height="11.6" />
      <line className="cls-1" x1="79.56" y1="63.17" x2="79.56" y2="57.82" />
      <line className="cls-1" x1="83.41" y1="63.17" x2="83.41" y2="57.82" />
      <line className="cls-1" x1="75.71" y1="63.17" x2="75.71" y2="57.82" />
      <line className="cls-1" x1="71.81" y1="63.17" x2="71.81" y2="57.82" />
      <rect className="cls-2" x="41.33" y="66.27" width="21.3" height="11.6" />
      <line className="cls-1" x1="53.88" y1="74.77" x2="53.88" y2="69.42" />
      <line className="cls-1" x1="57.73" y1="74.77" x2="57.73" y2="69.42" />
      <line className="cls-1" x1="50.03" y1="74.77" x2="50.03" y2="69.42" />
      <line className="cls-1" x1="46.13" y1="74.77" x2="46.13" y2="69.42" />
      <rect className="cls-2" x="41.33" y="54.67" width="21.3" height="11.6" />
      <line className="cls-1" x1="53.88" y1="63.17" x2="53.88" y2="57.82" />
      <line className="cls-1" x1="57.73" y1="63.17" x2="57.73" y2="57.82" />
      <line className="cls-1" x1="50.03" y1="63.17" x2="50.03" y2="57.82" />
      <line className="cls-1" x1="46.13" y1="63.17" x2="46.13" y2="57.82" />
      <rect className="cls-2" x="67.01" y="43.07" width="21.3" height="11.6" />
      <line className="cls-1" x1="79.56" y1="51.57" x2="79.56" y2="46.22" />
      <line className="cls-1" x1="83.41" y1="51.57" x2="83.41" y2="46.22" />
      <line className="cls-1" x1="75.71" y1="51.57" x2="75.71" y2="46.22" />
      <line className="cls-1" x1="71.81" y1="51.57" x2="71.81" y2="46.22" />
      <rect className="cls-2" x="67.01" y="31.47" width="21.3" height="11.6" />
      <line className="cls-1" x1="79.56" y1="39.97" x2="79.56" y2="34.62" />
      <line className="cls-1" x1="83.41" y1="39.97" x2="83.41" y2="34.62" />
      <line className="cls-1" x1="75.71" y1="39.97" x2="75.71" y2="34.62" />
      <line className="cls-1" x1="71.81" y1="39.97" x2="71.81" y2="34.62" />
      <line className="cls-1" x1="181.15" y1="77.87" x2="156.3" y2="77.87" />
      <rect
        className="cls-3"
        x="107.66"
        y="56.97"
        width="49.72"
        height="29.08"
      />
      <path
        className="cls-4"
        d="M107.66,57V86h49.71V57ZM119.4,80.81h-4V62.32h4Zm10.09,0h-4V62.32h4Zm9.95,0h-4V62.32h4Zm10,0h-4V62.32h4Z"
      />
      <line className="cls-5" x1="157.37" y1="102" x2="107.66" y2="102" />
      <path className="cls-6" d="M138.12,40.4a5.61,5.61,0,1,1-5.6-5.6V9.89" />
      <line className="cls-3" x1="115.59" y1="56.15" x2="129.13" y2="45.77" />
      <line className="cls-3" x1="149.44" y1="56.15" x2="135.9" y2="45.77" />
    </svg>
  )
}
