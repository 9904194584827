import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { format, differenceInCalendarDays, differenceInHours } from 'date-fns'
import Icon from '../Icon'
import Container from '../Container'
import Button from '../Button'
import Modal from '../Modal'
// import Journey from '../Journey'
import { Link } from 'gatsby'
import ReactGA from 'react-ga'

const bem = new BEMHelper('schedule-list')

export default class ScheduleList extends Component {
  static propTypes = {
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    contactElementId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    from: null,
    to: null,
    items: [],
  }

  state = {
    expanded: [],
    expandedJourney: [],
    animate: 'all',
    offsetHeight: null,
    bookingModalShowId: null,
    showBookingModal: null,
  }

  animateTimeout = null

  toggle = (id, key = 'expanded') => ({ target }) => {
    clearTimeout(this.animateTimeout)

    const expanded = [...this.state[key]]
    const index = expanded.indexOf(id)

    let offsetHeight = -target.closest('button').offsetHeight

    if (index > -1) {
      expanded.splice(index, 1)
      offsetHeight = target.closest('button').offsetHeight
    } else {
      expanded.push(id)
    }

    // Log toggeling in Google Analytics
    ReactGA.event({
      category: 'Scheduling Results',
      action: 'Toggle open route',
      value: `Key ${index}`,
    })

    this.setState(
      {
        [key]: expanded,
        offsetHeight,
        animate: key === 'expanded' ? id : null,
      },
      this.clearAnimations,
    )
  }

  showModal = ({ id }) => ({ target }) => {
    // Log toggeling in Google Analytics
    ReactGA.event({
      category: 'Scheduling Results',
      action: 'Open book by email modal',
    })
    this.setState({
      showBookingModal: target,
      bookingModalShowId: id,
    })
  }

  closeModal = () => {
    this.setState({
      showBookingModal: null,
      bookingModalShowId: null,
    })
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps() {
    if (this.state.animate !== 'all') {
      this.setState({ animate: 'all' })
    }
  }

  clearAnimations = () => {
    this.animateTimeout = setTimeout(() => {
      this.setState({
        animate: null,
        offsetHeight: null,
      })
    }, 500)
  }

  isDoor = ({ type }) => type === 'postal'

  Details = ({
    direction,
    type,
    location,
    address,
    mapLinkAddress,
    shipName,
    time,
  }) => {
    const isFrom = direction === 'departure'

    return (
      <section {...bem('details', [direction, 'more'])}>
        <h3 {...bem('sub-title')}>
          {isFrom ? 'From' : 'To'} {type === 'door' ? 'door' : 'port'}
        </h3>

        <div {...bem('info')}>
          {location.name}
          {type !== 'door' && ` (${location.code})`}
          {', '}
          {location.country}
        </div>

        <div {...bem('info')}>
          <span {...bem('label')}>{isFrom ? 'ETS' : 'ETA'}</span>
          <time {...bem('value')}>{format(time, 'ddd D. MMM, HH:mm')}</time>
        </div>
      </section>
    )
  }

  renderDetails = ({
    id,
    name,
    departure,
    arrival,
    closing,
    pickUpTime,
    stops,
    voyage,
  }) => {
    const { from, to } = this.props
    const { Details } = this
    const fromDoor = this.isDoor(from)
    const toDoor = this.isDoor(to)
    const priceLink = `/prices/?from=${from.id}&to=${to.id}`

    return (
      <div {...bem('more')}>
        <div {...bem('content')}>
          <Details
            direction="departure"
            type={fromDoor ? 'door' : 'ship'}
            location={from}
            address={from.name}
            shipName={name}
            time={departure}
          />

          <Details
            direction="arrival"
            type={toDoor ? 'door' : 'ship'}
            location={to}
            address={to.name}
            shipName={name}
            time={arrival}
          />
        </div>
        <div {...bem('even-more')}>
          <span {...bem('label', 'inline')}>Vessel</span>
          <span {...bem('value', 'inline')}>
            {name} ({voyage})
          </span>
        </div>

        <nav {...bem('actions')}>
          <Button
            secondary
            {...bem('button')}
            onClick={this.showModal({
              id,
            })}
          >
            Book by email
          </Button>
          <Button tertiary arrow={false} {...bem('button')} to={priceLink}>
            Get price
          </Button>
        </nav>
      </div>
    )
  }

  renderItem = ({
    id,
    name,
    departure,
    arrival,
    closing,
    pickUpTime,
    stops,
    voyage,
  }) => {
    const { expanded, animate } = this.state
    const { from, to } = this.props
    const dateFormat = 'D. MMM, HH:mm'
    const fromDoor = this.isDoor(from)
    const toDoor = this.isDoor(to)
    const isExpanded = expanded.indexOf(id) > -1

    const duration = () => {
      const days = differenceInCalendarDays(arrival, departure)

      if (days === 0) {
        const hours = differenceInHours(arrival, departure)
        return `${hours} hours`
      } else if (days === 1) {
        return `${days} day`
      }

      return `${days} days`
    }

    return (
      <section
        {...bem('item', {
          expanded: isExpanded,
          'animate-in': isExpanded && animate === id,
          'animate-out': !isExpanded && animate === id,
        })}
        key={id}
      >
        <button
          type="button"
          {...bem('toggle', {
            expanded: isExpanded,
          })}
          onClick={this.toggle(id)}
          aria-expanded={isExpanded}
        >
          <time {...bem('details', 'departure')}>
            <span {...bem('date', 'large')}>{format(departure, 'dddd')}</span>
            <span {...bem('date')}>{format(departure, dateFormat)}</span>
          </time>

          <div {...bem('illustation')}>
            <time {...bem('length')}>{duration()}</time>
            <div {...bem('icons')}>
              {fromDoor && (
                <>
                  <Icon icon="truck" {...bem('icon', 'truck')} />
                  <Icon
                    icon="chevron"
                    direction="right"
                    {...bem('direction')}
                  />
                </>
              )}
              <Icon icon="boatMedium" {...bem('icon', 'boat')} />
              {toDoor && (
                <>
                  <Icon
                    icon="chevron"
                    direction="right"
                    {...bem('direction')}
                  />
                  <Icon icon="truck" {...bem('icon', 'truck')} />
                </>
              )}
              <span
                {...bem('toggle-arrow', {
                  expanded: isExpanded,
                })}
              >
                <Icon icon="chevron" direction="down" {...bem('toggle-icon')} />
              </span>
            </div>
          </div>

          <time {...bem('details', 'arrival')}>
            <span {...bem('date', 'large')}>{format(arrival, 'dddd')}</span>
            <span {...bem('date')}>{format(arrival, dateFormat)}</span>
          </time>
        </button>

        {isExpanded &&
          this.renderDetails({
            id,
            name,
            departure,
            arrival,
            closing,
            pickUpTime,
            stops,
            voyage,
          })}
      </section>
    )
  }

  render() {
    const {
      offsetHeight,
      animate,
      showBookingModal,
      bookingModalShowId,
    } = this.state
    const { items, from, to } = this.props

    const expanded = bookingModalShowId
      ? items.find(({ id }) => id === bookingModalShowId)
      : null
    const priceLink = from && to ? `/prices/?from=${from.id}&to=${to.id}` : '/'
    const dateFormat = 'D. MMM, HH:mm'
    const emailSubject =
      from && to ? `Booking from ${from.code} to ${to.code}` : ''
    const emailBody =
      expanded && from && to
        ? `Hi\n\nI would like to book a shipment with ${expanded.name} (${
            expanded.voyage
          }).\n\nFROM\n${from.name} (${from.code}), ${
            from.country
          }\nETD: ${format(expanded.departure, dateFormat)}\n\nTO\n${
            to.name
          } (${to.code}), ${to.country}\nETA: ${format(
            expanded.arrival,
            dateFormat,
          )}\n\nCONTAINERS AND CONTENTS\n...\n\nQUOTE\n...`
        : ''

    return (
      <Container
        nodeType="nav"
        small
        {...bem('', {
          animate: animate === 'all',
        })}
        style={
          offsetHeight
            ? {
                '--schedule-height': `${offsetHeight}px`,
              }
            : {}
        }
      >
        {items.map(this.renderItem)}

        <div {...bem('schedule-table')}>
          <Link {...bem('link')} to="/ports-and-routes/schedule/">
            See the full schedule table
          </Link>
        </div>

        <Modal
          show={!!showBookingModal}
          from={showBookingModal}
          handleClose={this.closeModal}
          title="Book by email"
          medium
          actions={[
            {
              name: 'Prefilled email',
              mailto: `shortsea@ncl.no?subject=${encodeURI(
                emailSubject,
              )}&body=${encodeURI(emailBody)}`,
              onClick: () => {
                // Log toggeling in Google Analytics
                ReactGA.event({
                  category: 'Scheduling Results',
                  action: 'Prefilled email clicked',
                })
              },
              secondary: true,
            },
            {
              name: 'Close',
              onClick: this.closeModal,
              tertiary: true,
            },
          ]}
        >
          <p>
            Send an email to{' '}
            <a href="mailto:shortsea@ncl.no">shortsea@ncl.no</a> with the
            specifications for your shipment.
          </p>
          <p>
            Please <Link to={priceLink}>get prices</Link> first if you have no
            quote yet.
          </p>

          <pre {...bem('email-text')}>{emailBody}</pre>
        </Modal>
      </Container>
    )
  }
}
