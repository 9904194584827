import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Entries from '../Entries'
import DoorToDoor from '../Illustration/DoorToDoor'
import PortToPort from '../Illustration/PortToPort'
import Containers from '../Illustration/Containers'
import FromSvgString from '../Illustration/UploadedSvg'
import FromImageSrc from '../Illustration/UploadedImage'

class CarouselWidget extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
  }

  constructor(props) {
    super()

    this.transitionItemFirstKey = 'transition-item-first'
    this.transitionItemLastKey = 'transition-item-last'

    // How sensitive should the swipe action be?
    // If the difference between X on touch start and end is higher than 50,
    // it's considered a deliberate right/left swipe. Otherwise, it might be
    // because the user slightly swipes right/left when scrolling up/down, and
    // in this case we want to prevent unnecessary swiping.
    this.swipeSensitivityThreshold = 100

    this.canRotate = props.items.length > 1

    // Copy prop items by adding the "last" element first
    // and the "first" element last. This is used to fake "rotation"
    // for the carousel. A `transitionend`-listener checks if the current
    // item is the first or last element, and then performs a "silent"
    // transition to the start or end of the list.
    this.extendedItems = [
      // Clone and add last element as first element
      React.cloneElement(props.items[props.items.length - 1], {
        key: this.transitionItemFirstKey,
      }),

      // Add all items in between
      ...props.items,

      // Clone and add first element as last element
      React.cloneElement(props.items[0], { key: this.transitionItemLastKey }),
    ]

    // Resize timeout
    this.resizeTimer = null

    // Auto rotate timer
    this.autoRotateMilliseconds = 5000
    this.autoRotateTimer = null

    this.state = {
      // This is used to get the width of the element,
      // and then calculate how many pixels translateX should shift.
      slideElementWidth: 0,

      // Which slide the carousel is currently at.
      // This is increment everytime the a slide is shifted, and
      // corresponds to a index in `slideElements`
      slideCounter: 1,

      // The actual slider
      slideElement: null,

      // Store scrollLeft value. This is used for comparison to
      // decide the scroll-direction.
      // scrollLeft: 0,

      // Is a slide transition being performed?
      slideTransitionInProgress: false,

      // Handle swipe left right (for handheld devices)
      touchstartX: 0,
      touchendX: 0,
    }
  }

  componentDidMount() {
    // Add event-listener on resize.
    // This fetches the new size of the slide item so
    // that the next translateX-calculation uses the correct
    // client width.
    window.addEventListener('resize', () => {
      if (this.extendedItems.length > 0) {
        if (this.resizeTimer !== null) {
          clearTimeout(this.resizeTimer)
        }
        this.resizeTimer = setTimeout(() => {
          let slideElements = document.querySelectorAll('.carousel-item')
          this.setState(
            {
              slideElementWidth: slideElements[0].clientWidth,
            },
            () => {
              clearTimeout(this.resizeTimer)
            },
          )
        }, 500)
      }
    })

    // Get the DOM elements and update state.
    let slideElements = document.querySelectorAll('.carousel-item')
    let slideElementWidth = 0
    if (this.extendedItems.length > 0) {
      slideElementWidth = slideElements[0].clientWidth
    }
    this.setState(
      {
        slideElement: document.querySelector('.carousel-slide'),
        slideElementWidth: slideElementWidth,
      },
      () => {
        this.state.slideElement.addEventListener('transitionend', () => {
          let currentItem = this.extendedItems[this.state.slideCounter]
          if (currentItem.key === this.transitionItemLastKey) {
            // Transition ended for the "last" element.
            // Transitions to the first slide-element (clone of the last element) without
            // a transition animation to start over making it appear as the carousel rotates.
            this.state.slideElement.classList.remove('slide-animation')
            this.setState(
              {
                slideCounter: 1,
              },
              () => {
                let xTranslate =
                  -this.state.slideElementWidth * this.state.slideCounter
                this.state.slideElement.style.transform = `translateX(${xTranslate}px)`
              },
            )
          }
          if (currentItem.key === this.transitionItemFirstKey) {
            // Transition ended for the "first" element.
            // Transitions to the last slide-element (clone of the first element) without a
            // a transition animation to start over making it appear as the carousel rotates.
            this.state.slideElement.classList.remove('slide-animation')
            this.setState(
              {
                slideCounter: this.extendedItems.length - 2,
              },
              () => {
                let xTranslate =
                  -this.state.slideElementWidth * this.state.slideCounter
                this.state.slideElement.style.transform = `translateX(${xTranslate}px)`
              },
            )
          }
        })

        //
        // Handle "swipe"-event (for mobile/handheld devices)
        //
        document
          .querySelector('.carousel')
          .addEventListener('touchstart', event => {
            if (this.state.slideTransitionInProgress) {
              return
            }
            this.setState({
              touchstartX: event.changedTouches[0].screenX,
              slideTransitionInProgress: true,
            })
          })
        document
          .querySelector('.carousel')
          .addEventListener('touchend', event => {
            this.setState(
              {
                touchendX: event.changedTouches[0].screenX,
              },
              () => {
                this.setState({ slideTransitionInProgress: false }, () => {
                  const result = this.state.touchstartX - this.state.touchendX
                  if (Math.abs(result) > this.swipeSensitivityThreshold) {
                    this.stopAutoRotate()
                    if (result > 0) {
                      this.slideRight()
                    } else {
                      this.slideLeft()
                    }
                  }
                })
              },
            )
          })
      },
    )

    // Start auto-rotate
    if (this.canRotate) {
      this.startAutoRotate()
    }
  }

  stopAutoRotate() {
    if (this.autoRotateTimer !== null) {
      clearInterval(this.autoRotateTimer)
      this.autoRotateTimer = null
    }
  }

  startAutoRotate() {
    this.stopAutoRotate()
    this.autoRotateTimer = setInterval(() => {
      this.slideRight()
    }, this.autoRotateMilliseconds)
  }

  /**
   * Perform a slide.
   *
   * Sets the transition of the element, and calculates the value for
   * translateX based on the current width of the item and the slide
   * counter (how many pixels to be shifted on the x-axis).
   *
   * Note:
   * When `this.state.slideCounter` is the last index (the element of the
   * last index is a copy of the first element) a "silent" transition to the
   * back to the start is performed give the illusion of carousel rotation
   * forever. The silent transition is handled by a `transitionend`-listener
   * (see componentDidMount).
   */
  performSlide() {
    this.state.slideElement.classList.add('slide-animation')
    let xTranslate = -this.state.slideElementWidth * this.state.slideCounter
    this.state.slideElement.style.transform = `translateX(${xTranslate}px)`
  }

  slideRight() {
    if (
      !this.canRotate ||
      this.state.slideCounter >= this.extendedItems.length - 1
    ) {
      return
    }
    this.setState(
      {
        slideCounter: this.state.slideCounter + 1,
      },
      () => {
        this.performSlide()
      },
    )
  }

  slideLeft() {
    if (!this.canRotate || this.state.slideCounter <= 0) {
      return
    }
    this.setState(
      {
        slideCounter: this.state.slideCounter - 1,
      },
      () => {
        this.performSlide()
      },
    )
  }

  slideTo(itemIndex) {
    if (
      !this.canRotate ||
      (itemIndex < 1 || itemIndex > this.extendedItems.length - 1)
    ) {
      return
    }
    this.setState(
      {
        slideCounter: itemIndex,
      },
      () => {
        this.performSlide()
      },
    )
  }

  renderItemBrowser() {
    if (this.props.items.length <= 1) {
      return null
    }

    const browserDots = []
    for (let i = 1; i < this.extendedItems.length - 1; i++) {
      let item = this.extendedItems[i]
      browserDots.push(
        <span
          key={`browser-dot-${item.key}`}
          className={`carousel-browser-dot${
            i === this.state.slideCounter ? ' carousel-browser-dot--active' : ''
          }`}
          onClick={() => {
            this.stopAutoRotate()
            this.slideTo(i)
          }}
        />,
      )
    }
    return <div className="carousel-browser">{browserDots}</div>
  }

  render() {
    if (this.slideElement === null) {
      return null
    }

    const initialTranslateX =
      -this.state.slideElementWidth * this.state.slideCounter
    return (
      <>
        <div className="carousel">
          <div
            className="carousel-slide"
            style={{
              width: `${this.extendedItems.length * 100}%`,
              transform: `translateX(${initialTranslateX}px)`,
            }}
          >
            {this.extendedItems}
          </div>
        </div>
        {this.renderItemBrowser()}
      </>
    )
  }
}

export default class ServiceCarousel extends Component {
  constructor() {
    super()
  }

  renderFallback() {
    return (
      <>
        <Entries
          type="split"
          items={[
            {
              title: 'Door to Door',
              to: '/services/door-to-door/',
              description: `Door to door transportation via sea is a smart, cost-efficient alternative to using trailers, and just as easy to order.`,
              illustration: <DoorToDoor />,
            },
            {
              title: 'Port to Port',
              to: '/services/port-to-port/',
              description: `Ship it and collect it at your port of choice. Sea freight is simple, efficient and better for the environment.`,
              illustration: <PortToPort />,
            },
            {
              title: 'Containers and equipment',
              to: '/services/equipment/',
              description: `We provide 45´containers, 20’ & 40’ standard containers, reefer containers, palletwide containers and flatracks. `,
              illustration: <Containers />,
            },
          ]}
        />
      </>
    )
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            services: allServiceCarousel {
              edges {
                node {
                  isDummy
                  serviceCarouselData {
                    page
                    title
                    description
                    serviceUrlPath
                    svgRaw
                    imgUrl
                  }
                }
              }
            }
          }
        `}
        render={({ services: { edges: services } }) => {
          // Must have a dummy page if ServiceCarousel does not exist in backend, and the dummy must
          // have data to query on (for Gatsby graphql to work at all). If content is marked as dummy,
          // the fallback is rendered.
          if (services[0].node.isDummy) {
            return <>{this.renderFallback()}</>
          }
          const serviceCarouselData = services[0].node.serviceCarouselData

          let serviceItems = []
          let carouselItems = []
          let serviceCounter = 0
          const serviceCount = serviceCarouselData.length
          for (let i = 0; i < serviceCount; i++) {
            let serviceCarouselItem = serviceCarouselData[i]
            let illustration = <DoorToDoor />

            if (serviceCarouselItem.svgRaw) {
              illustration = <FromSvgString svg={serviceCarouselItem.svgRaw} />
            } else if (serviceCarouselItem.imgUrl) {
              illustration = <FromImageSrc src={serviceCarouselItem.imgUrl} />
            }

            serviceItems.push({
              title: serviceCarouselItem.title,
              description: serviceCarouselItem.description,
              to: serviceCarouselItem.serviceUrlPath || '/',
              illustration: illustration,
            })
            serviceCounter++

            // Add three and three services, or the remaining
            // services, as a "carousel item"
            if (serviceCounter === 3 || i + 1 === serviceCount) {
              carouselItems.push(
                <div className="carousel-item" key={`entry-${i}`}>
                  <Entries type={'split'} items={[...serviceItems]} />
                </div>,
              )
              serviceItems = []
              serviceCounter = 0
            }
          }

          return (
            <>
              <CarouselWidget items={carouselItems} />
            </>
          )
        }}
      />
    )
  }
}
