import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link } from 'gatsby'
import Icon from '../Icon'
import Container from '../Container'
import Img from 'gatsby-image'

const bem = new BEMHelper('entries')

export default function Entries ({ items, type, promoted, spaceless }) {
  return (
    <nav {...bem('', { [type]: type, promoted, spaceless })}>
      <Container
        nodeType="div"
        medium={type !== 'split'}
        large={type === 'split'}
        {...bem('wrapper')}
      >
        {items.map(
          ({ id, to, title, description, image, illustration }, index) => {
            const key = id || `${to}-${index}`
            const content = (
              <>
                {image && (
                  <Img
                    {...image}
                    expandable={false}
                    style={{ height: null, width: null }}
                    {...bem('image')}
                  />
                )}

                {illustration && (
                  <figure {...bem('illustration')}>{illustration}</figure>
                )}

                <div {...bem('content')}>
                  <h2 {...bem('title')}>{title}</h2>
                  <p {...bem('description')}>{description}</p>
                </div>

                <Icon icon="chevron" strong {...bem('arrow')} />
              </>
            )

            if (to.startsWith('http')) {
              return (
                <a
                  key={key}
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...bem('item', { illustration })}
                >
                  {content}
                </a>
              )
            }

            return (
              <Link key={key} to={to} {...bem('item', { illustration })}>
                {content}
              </Link>
            )
          },
        )}
      </Container>
    </nav>
  )
}

Entries.propTypes = {
  type: PropTypes.oneOf(['image', 'split']),
  promoted: PropTypes.bool,
  spaceless: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.object,
      pictogram: PropTypes.object,
    }),
  ).isRequired,
}
