export default function uniqueArray (array) {
  return array.reduce((results, item) => {
    if (
      results.map(i => JSON.stringify(i)).indexOf(JSON.stringify(item)) === -1
    ) {
      results.push(item)
    }

    return results
  }, [])
}
