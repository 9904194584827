import React from 'react'

export default function PortToPort () {
  return (
    <svg viewBox="0 0 208 108">
      <defs>
        <style
        >{`.ptp-1,.ptp-2,.ptp-3,.ptp-4,.ptp-7{fill:none;}.ptp-1,.ptp-2{stroke:#97caad;stroke-width:2px;}.ptp-1,.ptp-6,.ptp-7{stroke-linecap:square;}.ptp-1,.ptp-2,.ptp-3,.ptp-4,.ptp-6,.ptp-7{stroke-linejoin:round;}.ptp-2,.ptp-4{stroke-linecap:round;}.ptp-3,.ptp-4,.ptp-6,.ptp-7{stroke:#153356;}.ptp-3{stroke-width:4.19px;}.ptp-4,.ptp-6,.ptp-7{stroke-width:4px;}.ptp-5,.ptp-6{fill:#153356;}`}</style>
      </defs>
      <title>port-to-port</title>
      <line className="ptp-1" x1="129" y1="77.81" x2="25" y2="77.81" />
      <line className="ptp-1" x1="180" y1="78" x2="154" y2="78" />
      <polygon
        className="ptp-2"
        points="57.47 60.14 74.02 60.14 73.02 72.74 56.47 72.74 57.47 60.14"
      />
      <polygon
        className="ptp-2"
        points="73.64 64.99 77.87 64.99 80.35 70.01 79.03 72.74 73.02 72.74 73.64 64.99"
      />
      <circle className="ptp-2" cx="61.87" cy="75.14" r="2.4" />
      <circle className="ptp-2" cx="74.42" cy="75.14" r="2.4" />
      <polygon
        className="ptp-2"
        points="30.47 60.14 53.02 60.14 52.02 72.74 29.47 72.74 30.47 60.14"
      />
      <circle className="ptp-2" cx="34.87" cy="75.14" r="2.4" />
      <circle className="ptp-2" cx="46.42" cy="75.14" r="2.4" />
      <rect className="ptp-2" x="88.33" y="66.27" width="21.3" height="11.6" />
      <line className="ptp-1" x1="100.88" y1="74.77" x2="100.88" y2="69.42" />
      <line className="ptp-1" x1="104.73" y1="74.77" x2="104.73" y2="69.42" />
      <line className="ptp-1" x1="97.03" y1="74.77" x2="97.03" y2="69.42" />
      <line className="ptp-1" x1="93.13" y1="74.77" x2="93.13" y2="69.42" />
      <rect className="ptp-2" x="88.33" y="54.67" width="21.3" height="11.6" />
      <line className="ptp-1" x1="100.88" y1="63.17" x2="100.88" y2="57.82" />
      <line className="ptp-1" x1="104.73" y1="63.17" x2="104.73" y2="57.82" />
      <line className="ptp-1" x1="97.03" y1="63.17" x2="97.03" y2="57.82" />
      <line className="ptp-1" x1="93.13" y1="63.17" x2="93.13" y2="57.82" />
      <line className="ptp-3" x1="157.41" y1="34.95" x2="157.41" y2="25.58" />
      <line className="ptp-3" x1="129.17" y1="25.58" x2="129.17" y2="34.95" />
      <rect
        className="ptp-4"
        x="150.31"
        y="49.44"
        width="14.2"
        height="13.25"
      />
      <rect
        className="ptp-4"
        x="121.92"
        y="49.44"
        width="14.2"
        height="13.25"
      />
      <rect
        className="ptp-4"
        x="136.12"
        y="35.19"
        width="14.2"
        height="14.25"
      />
      <rect
        className="ptp-4"
        x="150.31"
        y="35.19"
        width="14.2"
        height="14.25"
      />
      <rect
        className="ptp-4"
        x="121.92"
        y="35.19"
        width="14.2"
        height="14.25"
      />
      <path
        className="ptp-5"
        d="M117.51,65.51l13.57,30.87L137,98.29l4.2,2.27V54l-22.61,8.83A2,2,0,0,0,117.51,65.51Z"
      />
      <polygon
        className="ptp-5"
        points="155.51 96.38 156.51 96.38 155.51 96.37 155.51 96.38"
      />
      <path
        className="ptp-5"
        d="M145.22,54v47l4.65-2.7,5.64-1.95,13.57-30.86A2,2,0,0,0,168,62.84Z"
      />
      <line className="ptp-6" x1="143.22" y1="25.58" x2="143.22" y2="15.59" />
      <line className="ptp-7" x1="122.28" y1="25.58" x2="164.15" y2="25.58" />
      <path
        className="ptp-7"
        d="M176.41,99.32h0a9.49,9.49,0,0,1-13.21,0h0a9.72,9.72,0,0,0-13.4,0,9.49,9.49,0,0,1-13.21,0h0a9.47,9.47,0,0,0-13.2,0h0a9.49,9.49,0,0,1-13.21,0h0a9.47,9.47,0,0,0-13.2,0h0a9.49,9.49,0,0,1-13.21,0"
      />
    </svg>
  )
}
