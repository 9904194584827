import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
// import { Link } from 'gatsby'
import Container from '../Container'
import ReactGA from 'react-ga'

const bem = new BEMHelper('tags')

export default class Tags extends Component {
  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    limit: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  static defaultProps = {
    handleClick: () => {},
  }

  constructor () {
    super()

    this.state = {
      expanded: false,
    }
  }

  toggle = value => () => {
    if (value) {
      // Log tag use in Google Analytics
      ReactGA.event({
        category: 'Scheduling Tags',
        action: 'Show more tags',
      })
    }
    this.setState({
      expanded: value,
    })
  }

  limit = (item, index) => {
    const { expanded } = this.state
    const { items, limit } = this.props

    return (
      (index < limit || items.length < limit || expanded || !limit) && index < 8
    )
  }

  render () {
    const { expanded } = this.state
    const { items, handleClick, limit } = this.props

    return (
      <Container nodeType="nav" small {...bem('', { expanded })}>
        <div {...bem('items')}>
          {items.filter(this.limit).map(({ from, to }) => (
            <button
              typs="button"
              key={`${from}-${to}`}
              {...bem('item')}
              onClick={() => {
                handleClick({ from, to })
                this.toggle(false)()
                // Log tag use in Google Analytics
                ReactGA.event({
                  category: 'Scheduling Tags',
                  action: 'Open previous search',
                  value: `${from} → ${to}`,
                })
              }}
            >
              {from}
              <span {...bem('icon')}>→</span>
              {to}
            </button>
          ))}

          {!expanded && items.length > limit && (
            <button
              type="button"
              {...bem('item', 'expand')}
              aria-label="Expand"
              onClick={this.toggle(true)}
            />
          )}
        </div>
      </Container>
    )
  }
}
