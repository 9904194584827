import './index.scss'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import Container from '../Container'
import Button from '../Button'
import Map from '../Map'

const bem = new BEMHelper('hero_vimeo_video')

export default class HeroVimeoVideo extends Component {
  static propTypes = {
    videoId: PropTypes.string,
    children: PropTypes.any,
    sizes: PropTypes.object,
    left: PropTypes.bool,
    overlay: PropTypes.bool,
    title: PropTypes.string,
    preamble: PropTypes.string,
    fluid: PropTypes.object,
    cta: PropTypes.string,
    link: PropTypes.string,
    map: PropTypes.bool,
    coordinates: PropTypes.array,
    centerPos: PropTypes.array,
  }

  static defaultProps = {
    overlay: true,
    width: 'small',
  }

  state = {
    expanded: false,
  }

  toggleExpand = value => () => {
    this.setState({
      expanded: value || value === false ? value : !this.state.expanded,
    })
  }

  renderMap = () => {
    const { expanded } = this.state

    const {
      videoId,
      children,
      fluid,
      left,
      overlay,
      title,
      preamble,
      cta,
      link,
      coordinates,
      centerPos,
      map,
      ...props
    } = this.props

    if (coordinates || centerPos) {
      return (
        <>
          <Map
            coordinates={coordinates || null}
            centerPos={centerPos || null}
            title={title}
            {...props}
            controls={expanded}
          />
          <Button
            {...bem('toggle')}
            tertiary
            subtle
            compact
            onClick={this.toggleExpand()}
          >
            {expanded ? 'Collapse' : 'Expand'}
          </Button>
        </>
      )
    }

    return null
  }

  render() {
    const {
      videoId,
      children,
      fluid,
      left,
      overlay,
      title,
      preamble,
      cta,
      link,
      coordinates,
      map,
    } = this.props
    const { expanded } = this.state
    const hasContent = (title || preamble) && !coordinates
    const Title = left ? 'h2' : 'h1'

    return (
      <header
        {...bem('', {
          left,
          overlay: overlay && !coordinates,
          text: hasContent,
          map: coordinates || map,
          expanded,
        })}
      >
        {this.renderMap()}
        <div {...bem('content')}>
          {hasContent && (
            <Container medium={!left} large={left}>
              <Title {...bem('title')}>{title}</Title>

              <p
                dangerouslySetInnerHTML={{ __html: preamble }}
                {...bem('preamble')}
              />

              {cta && link && (
                <Button tertiary to={link} {...bem('cta')}>
                  {cta}
                </Button>
              )}
            </Container>
          )}
          {children}
        </div>

        {videoId && (
          <div className={'hero_vimeo_video__iframewrapper'}>
            <iframe
              src={`https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&background=true`}
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </div>
        )}
      </header>
    )
  }
}

export function Overlap({ children, spaceless, dontOverlap }) {
  return (
    <section {...bem('overlap', { 'dont-overlap': dontOverlap, spaceless })}>
      {children}
    </section>
  )
}

Overlap.propTypes = {
  children: PropTypes.any.isRequired,
  spaceless: PropTypes.bool,
  dontOverlap: PropTypes.bool,
}
