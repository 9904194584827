import React from 'react'

export default function FromImageSrc(src) {
  return (
    <React.Fragment>
      <img
        style={{ margin: '0 auto' }}
        src={src.src}
        alt={'Service illustration'}
      />
    </React.Fragment>
  )
}
