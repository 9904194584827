import React from 'react'

export default function FromSvgString(svg) {
  return (
    <React.Fragment>
      <img
        style={{ margin: '0 auto' }}
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svg.svg)}`}
        alt={'Service illustration'}
      />
    </React.Fragment>
  )
}
